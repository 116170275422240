@charset "UTF-8";
/* ==============================
		  home.scss
================================= */
a:focus {
  box-shadow: 0 0 4px #004AA1;
}

.txtred {
  color: red;
}

#svg .st0 {
  enable-background: new;
}

.btn-area {
  display: flex;
  justify-content: space-around;
  width: 90%;
  margin: 24px auto;
  flex-flow: column;
}

@media screen and (min-width: 560px) {
  .btn-area {
    flex-flow: row;
    max-width: 840px;
  }
}

.common_btn {
  margin: 0 auto;
  padding: 16px 16px 10px;
  text-align: center;
  max-width: 220px;
  display: flex;
  align-items: center;
}

.common_btn01 {
  min-width: 240px;
}

@-webkit-keyframes hello {
  0% {
    stroke-dashoffset: 2000;
    fill: transparent;
    stroke-width: .5;
  }
  40% {
    stroke-dashoffset: 2000;
    fill: transparent;
  }
  60% {
    fill: transparent;
  }
  90% {
    stroke-width: .5;
  }
  100% {
    stroke-dashoffset: 0;
    fill: #fafafa;
    stroke-width: 0;
  }
}

#section01 {
  width: 100%;
  height: 100.3vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fafafa;
  background-image: url(../img/home/section1_bg_01.jpg);
  background-size: cover;
  background-position: 28%;
  position: relative;
}

@media screen and (min-width: 960px) {
  #section01 {
    height: 100vh;
  }
}

#section01 .icon {
  fill: transparent;
  stroke: #fafafa;
  stroke-dasharray: 2000;
  stroke-dashoffset: 0;
  stroke-width: 0;
  animation: hello 3s ease-in 1s;
  animation-fill-mode: both;
  width: 35vw;
  height: 35vw;
}

@media screen and (min-width: 560px) {
  #section01 .icon {
    width: 140px;
    height: 140px;
  }
}

#section01 .logo_text {
  fill: #fafafa;
  width: 50vw;
  height: 30vw;
}

@media screen and (min-width: 560px) {
  #section01 .logo_text {
    margin-top: 28px;
    margin-bottom: 20px;
    width: 190px;
    height: 86px;
  }
}

#section01 .contents {
  text-align: center;
  position: relative;
}

#section01 .contents .en {
  font-size: 9vw;
  line-height: 1;
}

@media screen and (min-width: 560px) {
  #section01 .contents .en {
    font-size: 53px;
  }
}

@media screen and (min-width: 960px) {
  #section01 .contents .en {
    font-size: 58px;
  }
}

#section01 .contents .en .num {
  font-size: 16vw;
}

@media screen and (min-width: 560px) {
  #section01 .contents .en .num {
    font-size: 90px;
  }
}

@media screen and (min-width: 960px) {
  #section01 .contents .en .num {
    font-size: 94px;
  }
}

.scroll {
  bottom: 1em;
  left: 0;
  position: absolute;
  display: block;
  width: 100%;
  text-align: center;
  color: #fafafa;
  z-index: 2;
}

@media screen and (min-width: 560px) {
  .scroll {
    bottom: 40px;
  }
}

@media screen and (min-width: 960px) {
  .scroll {
    bottom: 40px;
  }
}

#section02 {
  background-color: #232323;
  color: #fafafa;
  padding: 65px 16px 60px;
  display: none;
}

@media screen and (min-width: 960px) {
  #section02 {
    /*display: block;*/
  }
}

#section02 .tenawan {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
}

#section02 .tenawan .headline_sec {
  width: 210px;
}

#section02 .tenawan .headline_sec::before {
  font-size: 32px;
}

@media screen and (min-width: 960px) {
  #section02 .tenawan__headline {
    padding-top: 36px;
  }
}

#section02 .tenawan__form {
  display: flex;
  width: 510px;
  justify-content: space-between;
  margin-bottom: 29px;
}

#section02 .tenawan__form__list {
  width: 85px;
}

#section02 .tenawan__form__list:first-child {
  width: 185px;
}

#section02 .tenawan__form__list p {
  margin-bottom: 12px;
}

#section02 .tenawan__form__list select {
  font-family: 游明朝, "Yu Mincho", "Noto Serif JP", YuMincho, "Hiragino Mincho ProN", "Hiragino Mincho Pro", HGS明朝E, 宋体, serif;
  font-size: 18px;
  padding: 4px 8px 8px;
}

#section02 .tenawan__form__list select[name="ym"],
#section02 .tenawan__form__list select[name="d"] {
  padding: 4px 0 8px;
}

#section02 .tenawan__form__list select[name="st"],
#section02 .tenawan__form__list select[name="rm"],
#section02 .tenawan__form__list select[name="m"] {
  margin-right: 6px;
}

#section02 .tenawan__form__link a {
  color: #fff;
}

#section02 .tenawan__form__link a::before {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  display: inline-block;
  margin-right: 8px;
  border-width: 5px 0 5px 7px;
  border-color: transparent transparent transparent #ffffff;
}

#section02 .tenawan .common_btn01 {
  min-width: 240px;
  margin-top: 36px;
  font-family: 游明朝, "Yu Mincho", "Noto Serif JP", YuMincho, "Hiragino Mincho ProN", "Hiragino Mincho Pro", HGS明朝E, 宋体, serif;
}

@media screen and (min-width: 960px) {
  #section02 .tenawan .common_btn01 {
    min-width: 190px;
  }
}

@media screen and (min-width: 1120px) {
  #section02 .tenawan .common_btn01 {
    min-width: 240px;
  }
}

#section03 {
  width: 100%;
  position: relative;
  background-size: cover;
  background-position: center;
  padding: 80px 24px 80px 60px;
  line-height: 2.2;
  margin-bottom: 80vw;
}

@media screen and (min-width: 960px) {
  #section03 {
    color: #fafafa;
    background-image: url(../img/home/section2_bg.jpg);
    margin-bottom: 0;
  }
}

#section03::after {
  content: "";
  display: block;
  position: absolute;
  bottom: -80vw;
  left: 0;
  background-image: url(../img/home/section2_bg.jpg);
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 80vw;
}

@media screen and (min-width: 960px) {
  #section03::after {
    display: none;
  }
}

#section03 .concept {
  margin-bottom: 2em;
}

#section03 .concept .inline {
  display: inline;
}

#section03 .concept .sp-inline {
  display: inline;
}

@media screen and (min-width: 960px) {
  #section03 .concept .sp-inline {
    display: block;
  }
}

#section03 .shop {
  text-align: right;
}

#section04 {
  width: 100%;
  background: #fafafa;
  padding: 80px 0 0;
  line-height: 2.2;
  position: relative;
}

@media screen and (min-width: 960px) {
  #section04 {
    padding: 80px 0 0;
  }
}

#section04 .section-inner {
  padding: 0 24px 0 60px;
}

@media screen and (min-width: 960px) {
  #section04 .section-inner {
    padding: 0 24px 0;
  }
}

#section04 .room_abs {
  position: relative;
}

@media screen and (min-width: 960px) {
  #section04 .room_abs {
    position: absolute;
    left: 36px;
    width: 900px;
  }
}

@media screen and (min-width: 1120px) {
  #section04 .room_abs {
    width: 75%;
    max-width: 1100px;
    left: calc(50% - 550px);
  }
}

#section04 .room_abs .pc-inline {
  display: inline;
}

@media screen and (min-width: 1120px) {
  #section04 .room_abs .pc-inline {
    display: block;
  }
}

#section04 .room_abs .room_radio {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  flex-flow: row;
  max-width: 470px;
  margin-top: 24px;
  font-family: "Sorts Mill Goudy", serif;
  font-size: 18px;
}

@media screen and (min-width: 960px) {
  #section04 .room_abs .room_radio {
    font-size: 26px;
    line-height: 1;
  }
}

#section04 .room_abs .room_radio__input {
  position: relative;
  padding-left: 1.5em;
}

#section04 .room_abs .room_radio__input::before {
  content: "";
  width: 14px;
  height: 14px;
  display: block;
  position: absolute;
  left: 0;
  top: 6px;
  border: 1px solid #1c1c1c;
  transition: all .3s ease;
}

@media screen and (min-width: 1120px) {
  #section04 .room_abs .room_radio__input::before {
    top: 12px;
  }
}

#section04 .room_abs .room_radio input {
  opacity: 0;
  appearance: none;
  position: absolute;
}

#section04 .room_abs .room_radio input:checked + .room_radio__input::before {
  background-color: #1c1c1c;
}

#section04 .room_headline {
  right: 24px;
}

@media screen and (min-width: 960px) {
  #section04 .room_headline {
    right: 64px;
    margin-bottom: 64px;
  }
}

.room_type {
  padding: 30px 0 0;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-flow: column;
  margin-bottom: -4em;
}

@media screen and (min-width: 960px) {
  .room_type {
    padding: 150px 0 0;
    margin-bottom: 74px;
  }
}

.room_type .swiper-container .swiper-slide {
  left: 60px;
}

@media screen and (min-width: 960px) {
  .room_type .swiper-container .swiper-slide {
    left: calc(50% - 530px);
  }
}

.room_type .swiper-container .swiper-button-next,
.room_type .swiper-container .swiper-container-rtl .swiper-button-prev {
  right: 2vw;
}

@media screen and (min-width: 560px) {
  .room_type .swiper-container .swiper-button-next,
  .room_type .swiper-container .swiper-container-rtl .swiper-button-prev {
    right: 10vw;
  }
}

@media screen and (min-width: 960px) {
  .room_type .swiper-container .swiper-button-next,
  .room_type .swiper-container .swiper-container-rtl .swiper-button-prev {
    left: calc(100% - 500px);
    right: auto;
  }
}

.room_type .swiper-container .swiper-button-prev,
.room_type .swiper-container .swiper-container-rtl .swiper-button-next {
  left: 40px;
}

@media screen and (min-width: 960px) {
  .room_type .swiper-container .swiper-button-prev,
  .room_type .swiper-container .swiper-container-rtl .swiper-button-next {
    left: calc(50% - 590px);
  }
}

#section05 {
  background: #2f2f2f;
  position: relative;
  color: #fafafa;
  height: 0;
  padding-top: 300px;
  margin-top: 40%;
}

@media screen and (min-width: 960px) {
  #section05 {
    margin-bottom: 160px;
    padding-top: 480px;
    margin-top: 80px;
  }
}

#section05 .section-inner {
  position: absolute;
  top: -100px;
  left: 5%;
  background-image: url(../img/home/facilities%20_bg@2x.jpg);
  background-size: cover;
  width: 95%;
  padding: 50px 8%;
}

@media screen and (min-width: 960px) {
  #section05 .section-inner {
    width: 93%;
    padding: 50px 72px;
    left: calc(50% - 35% + 28px);
    height: 530px;
    top: 110px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

@media screen and (min-width: 1280px) {
  #section05 .section-inner {
    width: 1100px;
    left: calc(50% - 530px);
  }
}

@media screen and (min-width: 960px) {
  #section05 .section-inner > div {
    width: 50%;
  }
}

#section05 .section-inner .facilitien_contents {
  margin-top: 32px;
}

#section05 .section-inner .facilitien_contents .btn_cen {
  margin-top: 18px;
}

@media screen and (min-width: 960px) {
  #section05 .section-inner .facilitien_contents .btn_cen {
    text-align: left;
  }
}

@media screen and (min-width: 960px) {
  #section05 .section-inner .headline_sec.break::before {
    top: -55px;
  }
}

#section06 .section-inner {
  padding: 80px 24px 0 60px;
}

@media screen and (min-width: 1120px) {
  #section06 .section-inner {
    padding: 80px 0 0;
    left: calc(20px);
    position: relative;
  }
}

#section06 .craft_content {
  margin-bottom: 24px;
}

#section06 .craft_content .inline {
  display: inline;
}

@media screen and (min-width: 960px) {
  #section06 .craft_content .inline {
    display: block;
  }
}

@media screen and (min-width: 960px) {
  #section06 .craft_image {
    display: flex;
    justify-content: space-between;
    margin-bottom: 80px;
  }
}

#section06 .craft_image__figure {
  margin-bottom: 24px;
}

@media screen and (min-width: 960px) {
  #section06 .craft_image__figure {
    width: 49%;
  }
}

@media screen and (min-width: 960px) {
  #section06 .craft_image__figure:nth-child(2n) {
    position: relative;
    top: 80px;
  }
}

#section07 .section-inner {
  padding: 80px 16px 80px 60px;
}

@media screen and (min-width: 1120px) {
  #section07 .section-inner {
    padding: 80px 0;
    left: calc(20px);
    position: relative;
  }
}

#section07 .info_list {
  margin: 32px 0 0;
}

@media screen and (min-width: 960px) {
  #section07 .info_list {
    display: flex;
    justify-content: space-around;
  }
}

#section07 .info_list a {
  color: #1c1c1c;
}

#section07 .card {
  margin-bottom: 40px;
}

@media screen and (min-width: 960px) {
  #section07 .card {
    width: 100%;
  }
}

#section07 .card__header {
  margin-bottom: 16px;
}

@media screen and (min-width: 960px) {
  #section07 .card__figure {
    width: 20vw;
    height: 20vw;
  }
}

@media screen and (min-width: 1280px) {
  #section07 .card__figure {
    width: 350px;
    height: 350px;
  }
}

#section07 .card__figure img {
  object-fit: cover;
}

@media screen and (min-width: 960px) {
  #section07 .card__figure img {
    width: 20vw;
    height: 20vw;
  }
}

@media screen and (min-width: 1280px) {
  #section07 .card__figure img {
    width: 350px;
    height: 350px;
  }
}

#section07 .card__titile {
  font-size: 15px;
}

#section07 .card__subtitle {
  font-size: 13px;
  font-family: "Sorts Mill Goudy", serif;
}

.headline {
  font-size: 17px;
  font-weight: 400;
  margin-bottom: 48px;
}

.headline span {
  border-bottom: 1px solid #333;
  padding-bottom: .3em;
}

.inline {
  display: inline;
}

.adress {
  /*margin-top: .5em;*/
  /*text-align: center;*/
}

.adress a {
  border-bottom: 1px solid #333;
}

.access__parking {
  display: flex;
  justify-content: flex-start;
  flex-flow: row;
  margin-bottom: 24px;
}

.access__parking .parking_link {
  font-size: 12px;
  text-decoration: underline;
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  color: #fafafa;
  height: 13px;
}

@media screen and (min-width: 1120px) {
  .access__parking .parking_link {
    font-size: 12px;
  }
}

.access__parking .parking_link::after {
  content: "";
  display: block;
  width: 13px;
  height: 13px;
  margin: 0 20px 0 8px;
  background: url(../img/common/blank_w.png);
  background-repeat: no-repeat;
}
