@charset "utf-8";
@import "mixin", "base";

/* ==============================
		  home.scss
================================= */
a:focus {
  box-shadow: 0 0 4px #004AA1;
}

.txtred {
  color: red;
}

#svg .st0 {
  enable-background: new;
}

.btn-area {
  display: flex;
  justify-content: space-around;
  width: 90%;
  margin: 24px auto;
  flex-flow: column;

  @include mq(s) {
    flex-flow: row;
    max-width: 840px;
  }

}

.common_btn {
  margin: 0 auto;
  padding: 16px 16px 10px;
  text-align: center;
  max-width: 220px;
  display: flex;
  align-items: center;
}

.common_btn01 {
  min-width: 240px;
}

@-webkit-keyframes hello {
  0% {
    stroke-dashoffset: 2000;
    fill: transparent;
    stroke-width: .5;
  }

  40% {
    stroke-dashoffset: 2000;
    fill: transparent;
  }

  60% {
    fill: transparent;
  }

  90% {
    stroke-width: .5;
  }

  100% {
    stroke-dashoffset: 0;
    fill: #fafafa;
    stroke-width: 0;
  }
}

// ==============================================
//                 セクション01
// ==============================================

#section01 {
  width: 100%;
  height: 100.3vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fafafa;
  background-image: url(../img/home/section1_bg_01.jpg);
  background-size: cover;
  background-position: 28%;
  position: relative;

  @include mq(m) {
    height: 100vh;
  }

  .icon {
    fill: transparent;
    stroke: #fafafa;
    stroke-dasharray: 2000;
    stroke-dashoffset: 0;
    stroke-width: 0;
    animation: hello 3s ease-in 1s;
    animation-fill-mode: both;
    width: 35vw;
    height: 35vw;

    @include mq(s) {
      width: 140px;
      height: 140px;
    }
  }

  .logo_text {
    fill: #fafafa;
    width: 50vw;
    height: 30vw;

    @include mq(s) {
      margin-top: 28px;
      margin-bottom: 20px;
      width: 190px;
      height: 86px;
    }
  }

  .contents {
    text-align: center;
    position: relative;

    .en {
      font-size: 9vw;
      line-height: 1;

      @include mq(s) {
        font-size: 53px;
      }

      @include mq(m) {
        font-size: 58px;
      }

      .num {
        font-size: 16vw;

        @include mq(s) {
          font-size: 90px;
        }

        @include mq(m) {
          font-size: 94px;
        }
      }

    }
  }

  .animate {}

}


.scroll {
  bottom: 1em;
  left: 0;
  position: absolute;
  display: block;
  width: 100%;
  text-align: center;
  color: #fafafa;
  z-index: 2;

  @include mq(s) {
    bottom: 40px;
  }

  @include mq(m) {
    bottom: 40px;
  }

}



// ==============================================
//                 セクション02
// ==============================================

#section02 {
  background-color: #232323;
  color: $white;
  padding: 65px 16px 60px;
  display: none;

  @include mq(m) {
    /*display: block;*/
  }

  .tenawan {
    display: flex;
    justify-content: space-between;
    font-size: 14px;

    .headline_sec {

      width: 210px;

      &::before {
        font-size: 32px;
      }
    }

    &__headline {
      @include mq(m) {
        padding-top: 36px;
      }
    }

    &__form {
      display: flex;
      width: 510px;
      justify-content: space-between;
      margin-bottom: 29px;

      &__list {
        width: 85px;

        &:first-child {
          width: 185px;
        }

        p {
          margin-bottom: 12px;
        }

        select {
          font-family: $jp_font;
          font-size: 18px;
          padding: 4px 8px 8px;
        }

        select[name="ym"],
        select[name="d"],
          {
          padding: 4px 0 8px;
        }

        select[name="st"],
        select[name="rm"],
        select[name="m"] {
          margin-right: 6px;
        }
      }

      &__link {


        a {
          color: #fff;

          &::before {
            content: "";
            width: 0;
            height: 0;
            border-style: solid;
            display: inline-block;
            margin-right: 8px;
            border-width: 5px 0 5px 7px;
            border-color: transparent transparent transparent #ffffff;
          }
        }
      }


    }

    .common_btn01 {
      min-width: 240px;
      margin-top: 36px;
      font-family: $jp_font;

      @include mq(m) {
        min-width: 190px;
      }

      @include mq(l) {
        min-width: 240px;
      }

    }
  }
}


// ==============================================
//                 セクション03
// ==============================================

#section03 {
  width: 100%;
  position: relative;
  background-size: cover;
  background-position: center;
  padding: 80px 24px 80px 60px;
  line-height: 2.2;
  margin-bottom: 80vw;

  @include mq(m) {
    color: $white;
    background-image: url(../img/home/section2_bg.jpg);
    margin-bottom: 0;
  }

  &::after {
    content: "";
    display: block;
    position: absolute;
    bottom: -80vw;
    left: 0;
    background-image: url(../img/home/section2_bg.jpg);
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 80vw;

    @include mq(m) {
      display: none;
    }
  }

  .concept {
    margin-bottom: 2em;

    .inline {
      display: inline;
    }

    .sp-inline {
      display: inline;

      @include mq(m) {
        display: block;
      }
    }
  }

  .shop {
    text-align: right;
  }


}


// ==============================================
//                 セクション04
// ==============================================



#section04 {
  width: 100%;
  background: #fafafa;
  padding: 80px 0 0;
  line-height: 2.2;
  position: relative;

  @include mq(m) {
    padding: 80px 0 0;
  }

  .section-inner {
    padding: 0 24px 0 60px;

    @include mq(m) {
      padding: 0 24px 0;
    }
  }

  .room_abs {
    position: relative;

    @include mq(m) {
      position: absolute;
      left: 36px;
      width: 900px;
    }

    @include mq(l) {
      width:75%;
      max-width: 1100px;
      left: calc(50% - 550px);
    }

    .pc-inline {
      display: inline;

      @include mq(l) {
        display: block;
      }
    }

    .room_radio {
      display: flex;
      justify-content: space-between;
      flex-wrap: nowrap;
      flex-flow: row;
      max-width: 470px;
      margin-top: 24px;
      font-family: $en_font;
      font-size: 18px;

      @include mq(m) {
        font-size: 26px;
        line-height: 1;
      }



      &__input {
        position: relative;
        padding-left: 1.5em;

        &::before {
          content: "";
          width: 14px;
          height: 14px;
          display: block;
          position: absolute;
          left: 0;
          top: 6px;
          border: 1px solid $black;
          transition: all .3s ease;

          @include mq(l) {
            top: 12px;
          }
        }
      }

      input {
        opacity: 0;
        appearance: none;
        position: absolute;

        &:checked + .room_radio__input::before {
          background-color: $black
        }

        &:focus + .room_radio__input {
          // box-shadow: 0 0 4px #004AA1;
        }
      }


    }
  }

  .room_headline {
    right: 24px;

    @include mq(m) {
      right: 64px;
      margin-bottom: 64px;
    }
  }

}


.room_type {
  padding: 30px 0 0;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-flow: column;
  margin-bottom: -4em;

  @include mq(m) {
    padding: 150px 0 0;
    margin-bottom: 74px;
  }

  .swiper-container {

    .swiper-slide {
      left: 60px;

      @include mq(m) {
        left: calc(50% - 530px);
      }

    }

    .swiper-button-next,
    .swiper-container-rtl .swiper-button-prev {
      right: 2vw;

      @include mq(s) {
        right: 10vw;
      }

      @include mq(m) {
        left: calc(100% - 500px);
        right: auto;
      }

    }

    .swiper-button-prev,
    .swiper-container-rtl .swiper-button-next {
      left: 40px;

      @include mq(m) {
        left: calc(50% - 590px);
      }

    }


  }


  .room_headline {}
}

// ==============================================
//                 セクション05
// ==============================================


#section05 {
  background: $gray_b;
  position: relative;
  color: $white;
  height: 0;
  padding-top: 300px;
  margin-top: 40%;

  @include mq(m) {
    margin-bottom: 160px;
    padding-top: 480px;
    margin-top: 80px;
  }

  .section-inner {
    position: absolute;
    top: -100px;
    left: 5%;
    background-image: url(../img/home/facilities%20_bg@2x.jpg);
    background-size: cover;
    width: 95%;
    padding: 50px 8%;

    @include mq(m) {
      width: 93%;
      padding: 50px 72px;
      left: calc(50% - 35% + 28px);
      height: 530px;
      top: 110px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    @include mq(xl) {
      width: 1100px;
      left: calc(50% - 530px);
    }

    & > div {
      @include mq(m) {
        width: 50%;
      }
    }

    .facilitien_contents {
      margin-top: 32px;

      .btn_cen {
        margin-top: 18px;

        @include mq(m) {
          text-align: left;
        }

      }
    }

    .headline_sec.break::before {
      @include mq(m) {
        top: -55px;
      }
    }


  }
}


// ==============================================
//                 セクション06
// ==============================================
#section06 {

  .section-inner {
    padding: 80px 24px 0 60px;

    @include mq(l) {
      padding: 80px 0 0;
      left: calc(20px);
      position: relative;
    }

  }

  .craft_content {
    margin-bottom: 24px;

    .inline {
      display: inline;

      @include mq(m) {
        display: block;
      }
    }
  }

  .craft_image {
    @include mq(m) {
      display: flex;
      justify-content: space-between;
      margin-bottom: 80px;
    }

    &__figure {
      margin-bottom: 24px;

      @include mq(m) {
        width: 49%;
      }

      &:nth-child(2n) {
        @include mq(m) {
          position: relative;
          top: 80px;
        }
      }
    }
  }

}

// ==============================================
//                 セクション07
// ==============================================
#section07 {
  .section-inner {
    padding: 80px 16px 80px 60px;

    @include mq(l) {
      padding: 80px 0;
      left: calc(20px);
      position: relative;
    }

  }


  .info_list {
    margin: 32px 0 0;

    @include mq(m) {
      display: flex;
      justify-content: space-around;
    }

    a {
      color: $black;
    }
  }

  .card {
    margin-bottom: 40px;

    @include mq(m) {
      width: 100%;
    }

    &__header {
      margin-bottom: 16px;
    }

    &__figure {
      @include mq(m) {
        width: 20vw;
        height: 20vw;
      }

      @include mq(xl) {
        width: 350px;
        height: 350px;
      }

      img {
        object-fit: cover;

        @include mq(m) {
          width: 20vw;
          height: 20vw;
        }

        @include mq(xl) {
          width: 350px;
          height: 350px;
        }

      }
    }

    &__image {}

    &__body {}

    &__titile {
      font-size: 15px;
    }

    &__subtitle {
      font-size: 13px;
      font-family: $en_font;
    }
  }
}


.headline {
  font-size: 17px;
  font-weight: 400;
  margin-bottom: 48px;

  span {
    border-bottom: 1px solid #333;
    padding-bottom: .3em;
  }
}

.inline {
  display: inline;
}

.adress {
  /*margin-top: .5em;*/
  /*text-align: center;*/

  a {
    border-bottom: 1px solid #333;
  }

}

.access__parking {
  display: flex;
  justify-content: flex-start;
  flex-flow: row;
  margin-bottom: 24px;

  @include mq(l) {}

  .parking_link {
    font-size: 12px;
    text-decoration: underline;
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
    color: #fafafa;
    height: 13px;

    @include mq(l) {
      font-size: 12px;
    }

    &::after {
      content: "";
      display: block;
      width: 13px;
      height: 13px;
      margin: 0 20px 0 8px;
      background: url(../img/common/blank_w.png);
      background-repeat: no-repeat;

    }
  }
}
